<template>
  <div class="full_popup">
    <div class="row h-100 m-0 align-items-center justify-content-center">
      <div class="col-11 col-md-8 col-lg-7 col-xxl-6 p-0 card_item">
        <div class="row mx-0 h-100 justify-content-center">
          <div class="col-12 p-3 pb-0">
            <div class="row m-0 justify-content-center holder">
              <div class="bg_holder">
                <div class="background" >
                </div>
              </div>
              <div class="col-12 p-3 above">
                <div class="row">
                  <div class="col-md-10 col-8 offset-2 offset-md-1 pt-2">
                    <div class="row justify-content-center">
                      <div class="col-auto text-center p-0 card_title">
                        Pay Via Cheque
                      </div>
                    </div>
                  </div>
                  <div class="col-2 col-md-1 text-end cursor" @click="close">
                    <ios-close-icon class="inline-icon green-text-dark" />
                  </div>
                </div>
                <div class="row">
                  <!-- <transition-group name="fade" mode="out-in"> -->
                    <!--PAGE 1-->
                    <div class="col-12" v-if="page === 0" key="0">
                      <div class="row mx-0 my-4 justify-content-center align-items-center" >
                        <div class="col-12 text-center">
                          Please include the reference above with your cheque.
                        </div>
                      </div>
                      <div class="row mx-0 my-4">
                        <div class="col-md-6 mb-3">
                          <div class="row mx-0 h-100">
                            <div class="col-12 p-4 card_item h-100">
                              <div class="row mx-0 justify-content-center">
                                <div class="col-12 text-center green-text-dark medium">
                                  Payment Details:
                                </div>
                                <div class="col-12 text-center p-0 mt-3 font19 green-text medium">
                                  Amount: {{total}}
                                </div>
                                <div class="col-9 text-center pt-3 border-bottom">
                                </div>
                                <div class="col-12 text-center mt-3 p-0 font19 green-text medium">
                                  Reference: {{lastTransactionRef}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 mb-3">
                          <div class="row mx-0 h-100">
                            <div class="col-12 p-4 card_item h-100">
                              <div class="row mx-0 justify-content-center">
                                <div class="col-12 text-center green-text-dark medium">
                                  Cheque Details:
                                </div>
                                <div class="col-12 text-center mt-2 p-0 green-text medium">
                                  Payable To: Al Imdaad Foundation UK
                                </div>
                                <div class="col-12 text-center pt-2 border-bottom">
                                </div>
                                <div class="col-12 text-center p-0 mt-2 green-text medium">
                                  Post To: 185 Audley Range, Blackburn, Lancashire, BB1 1TH
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--END PAGE 1-->
                    <!--PAGE 2-->
                    <div class="col-12" v-if="page === 1" key="1">
                      <div class="row mx-0 justify-content-center align-items-center" >
                        <div class="col-5 text-center">
                          <img src="@/components/animations/tickAnimation.gif" v-if="isApp" class="img-fluid" />
                          <TickAnimation v-else />
                        </div>
                      </div>
                      <div class="row mx-0 mb-2 justify-content-center align-items-center" >
                        <div class="col-12 text-center green-text-dark bold font29">
                          Jazakallah Khair
                        </div>
                      </div>
                      <div class="row mx-0 mb-3 justify-content-center align-items-center" >
                        <div class="col-md-12 col-10 text-center green-text-dark medium">
                          You can find this donation under "My Pledges" in your Donor Profile.
                        </div>
                      </div>
                    </div>
                    <!--END PAGE 2-->
                  <!-- </transition-group> -->
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 p-0 align-self-end above">
            <div class="row m-0 background-green py-3 justify-content-center">
              <div class="col-auto">
                <Button color="green_light" width="100" btnText="Next" icon="arrow" @buttonClicked="next" v-if="page === 0">
                  <IconArrowForward color="white" />
                </Button>
                <Button color="green_light" width="100" btnText="Finish" icon="arrow" @buttonClicked="finish" v-else-if="page === 1">
                  <IconArrowForward color="white" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('../components/Button.vue')),
    TickAnimation: defineAsyncComponent(() => import('../components/animations/TickAnimation.vue')),
    IconArrowForward: defineAsyncComponent(() => import('../components/icons/IconArrowForward.vue')),
    'ios-close-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close.vue'))
  },
  name: 'Pay Via Cheque',
  props: ['total'],
  data () {
    return {
      page: 0,
      bank: ''
    }
  },
  computed: {
    ...mapGetters([
      'lastTransactionRef', 'isApp'
    ])
  },
  methods: {
    next () {
      this.page = this.page + 1
    },
    finish () {
      this.closeAll()
      window.scroll(0, 0)
      this.$router.push('/profile/2')
    },
    close () {
      this.$emit('close')
    },
    closeAll () {
      this.$emit('closeAll')
    }
  }
}
</script>
<style scoped>
.full_popup {
  position: fixed;
  z-index: 5;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  left: 0;
  top: 0;
}
.card_item {
  background-color: #F4F4F4F7;
  border-radius: 22px;
  box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%);
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.card_item_inner {
  background-color: rgb(249, 250, 251);
  border-radius: 15px;
  /* box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%); */
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.holder {
  position: relative;
  z-index: 2;
}
.above {
  z-index: inherit;
}
.bg_holder {
  position: absolute;
  left: -1.5rem;
  top: -1.5rem;
  z-index: 1;
  background-image: url(../assets/images/bg.png);
  background-size: 32% auto;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  max-width: calc(100% + 3rem);
  width: calc(100% + 3rem);
  height: calc(100% + 1.5rem);
  padding: 0;
}
.background {
  background-image: linear-gradient(180deg, #fffffff7 0%, #ffffff 100%);
  position: absolute;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  background-position: top center;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.card_title {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: var(--green-color);
}
.border_bottom {
  border-bottom: 1px solid var(--gold-color);
}
.profile_img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  position: relative;
  border: 2px solid #fff;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 5px 5px 35px 0px rgb(0 0 0 / 17%);
}
.profile_img img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.item_img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
  background-color: rgba(0, 0, 0, 0.1);
}
.item {
  color: #4E5356;
  font-size: 15px;
}
.delete {
  color: #E0E0E0;
  cursor: pointer;
}
.delete:hover {
  color: var(--red-color);
}
.borders {
  border-bottom: 1px solid #E0E0E0;
}
.borders:hover {
  background-color: rgb(244, 244, 244);
}
.hide {
  visibility: hidden;
}
.background-grey {
  background-color: rgb(244, 244, 244);
}
.item_nav {
  background-color: rgba(0, 0, 0, 0.53);
}
.hover:hover {
  color: var(--gold-color);
}
.disabled {
  opacity: 0.7;
}
.card_item {
  background-color: #fff;
  border-radius: 13px;
  box-shadow: 5px 5px 15px 0px rgb(0 0 0 / 9%);
  /* box-shadow: rgba(0, 0, 0, 0.09) 5px 5px 10px 0px; */
  color: var(--normal-color);
}
.card_title2 {
  font-family: "quicksand_medium", Sans-serif;
  font-weight: 500;
  color: var(--green-color-dark);
}
.holder {
  position: relative;
}
.above {
  z-index: 1;
}
.bg_holder {
  position: absolute;
  left: -1.5rem;
  top: -1.5rem;
  z-index: 1;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  background-image: linear-gradient(rgba(255, 255, 255, 0.98) 0%, rgb(255, 255, 255) 100%);
  max-width: calc(100% + 3rem);
  width: calc(100% + 3rem);
  height: calc(100% + 1.5rem);
  padding: 0;
}
.background {
  background-image: url(../assets/images/bg.png);
  position: absolute;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  background-size: 50%;
  opacity: 0.02;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.font29 {
    font-size: 21px;
  }
@media (min-width: 992px) {
  .card_title {
    font-size: 18px;
  }
  .bank-image {
    height: 40px;
  }
  .font29 {
    font-size: 29px;
  }
}
</style>
